main {

  &.main-content {
    padding-bottom: 3rem;
    padding-top: 4rem;

    @media only screen and(min-width: $desktop-size) {
      border-top: 0;
      padding-bottom: 6rem;
    }
  }
}

.hiring {

  a {
    font-size: 1.2rem;
  }
  
  h2 {
    font-size: 1.5rem;
    font-weight: normal;
    line-height: 1.8em;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    @media only screen and(min-width: $desktop-size) {
      font-size: 2rem;
    }
  }
}

.overlay {
  background: $color-black;
  bottom: 0;
  left: 0;
  opacity: 0;
  position: fixed;
  right: 0;
  top: 0;
  transition: all 0.2s ease-in-out;
  visibility: hidden;
  z-index: 9999;

  &.active {
    opacity: 0.1;
    visibility: visible;
  }
}

.page-breadcrumb {
  text-transform: uppercase;
}