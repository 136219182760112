.page-about {

  section {
    padding-top: 4rem;

    @media only screen and(min-width: $desktop-size) {
      padding-top: 6rem;
    }

    &:first-child {
      padding-top: 0;
    }
    
    &.mission {
    
      h2 {
        font-weight: normal;
        line-height: 1.8em;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }
    }
    
    &.priorities {
      
      .about-priority {
        margin-bottom: 2rem;
      }  
    }    

    &.ai {
      background-color: $color-gray-cool-5;
      margin-top: 4rem;
      padding: 4rem 0;
    
      @media only screen and(min-width: $desktop-size) {
        margin-top: 6rem;
        padding: 6rem 0;
      }
    
      p {
        color: $color-black;
        font-size: 1.2rem;
        line-height: 1.6em;
      }
    }    
  }
}