$font-serif: Merriweather Web, serif;
$font-body: Source Sans Pro, Helvetica Neue, Helvetica, Roboto, Arial, sans-serif;

a {
  color: $color-black;

  &:hover {
    color: $color-black;
    text-decoration: underline;
  }

  &:visited {
    color: $color-black;

    &:hover {
      color: $color-black;
      text-decoration: underline;
    }
  }

  &.headline-link {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

body {
  font-size: 1rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-serif;
}

hr {
  background-color: $color-black;
  border: none;
  height: 1px;
}

p {
  color: $color-black;
  line-height: 1.4em;
}

.color-white {
  color: $color-white;
}

.font-sm {
  font-size: 0.8rem !important;
}

.breadcrumb {
  font-size: 0.9rem;
  font-weight: bold;
  margin-bottom: 1.5rem;
  position: relative;
  text-transform: uppercase;

  &::after {
    bottom: -4px;
    border-bottom: 3px solid #000;
    content: "";
    left: 0;
    position: absolute;
    width: 25px;
  }
}

.long-link {
  word-wrap: break-word;
}

.square-link {
  color: #000;
  display: inline-block;
  font-weight: bold;
  line-height: 1rem;
  padding-left: 1.75rem;
  position: relative;
  text-transform: uppercase;

  &:hover {
    &::after {
      left: 0.15rem;
    }
  }

  &::after {
    background: $color-brand-teal url("../img/uswds/angle-arrow-down-white.svg");
    background-position: center center;
    background-size: 0.85rem;
    background-repeat: no-repeat;
    color: #fff;
    content: "";
    height: 1.25rem;
    left: 0;
    position: absolute;
    transform: rotate(-90deg);
    transition: left 0.1s ease-in-out;
    width: 1.25rem;
  }
}

li {
  line-height: 1.5rem;
  margin: 1rem;
}

.font-size-sm {
  font-size: 0.9rem !important;
}

.visually-hidden {
  clip-path: inset(100%);
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
