.grid-container-sm {
  margin: 0 auto;
  max-width: 900px;
  padding: 0 1rem;

  @media only screen and(min-width: $desktop-size) {
    padding: 0 2rem;
  }
}

.grid-container-lg {
  margin: 0 auto;
  max-width: 1200px;
  padding: 0 2rem;

  @media only screen and(min-width: $desktop-size) {
    padding: 0 2rem;
  }
}