@import "../_includes/news-item";

.page-home {

  main {

    &.main-content {
      padding-top: 0;
    }

    .hero {
      margin-bottom: 75px;

      h1 {
        font-weight: normal;
        margin: 0 0 1rem;
        font-size: 26px;

        @media only screen and(min-width: $tablet-size) {
          font-size: 2.5rem;
        }

        @media only screen and(min-width: $desktop-size) {
          font-size: 3rem;
        }
      }

      &-left {
        padding-top: 2rem;

        @media only screen and(min-width: $tablet-size) {
          padding-top: 6rem;
        }

        .gray-box {
          background-color: $color-gray-cool-5;
          bottom: -45px;
          display: none;
          height: 90px;
          position: absolute;
          max-width: 1160px;
          width: 140%;

          @media only screen and(min-width: $tablet-size) {
            display: block;
          }
        }
      }

      &-right {

        img {
          display: block;
          margin: 0 auto;
          max-width: 450px;
          width: 100%;
        }
      }

      &-left-content {
        margin: 0 auto;
        max-width: 600px;
        padding: 1rem 2rem calc(1rem + 30px) 1rem;
        width: 100%;

        @media only screen and(min-width: $desktop-size) {
          padding: 2rem;
          padding-bottom: calc(2rem + 30px);
        }

        p {

          @media only screen and(min-width: $tablet-size) {
            font-size: 1.1rem;
          }

          @media only screen and(min-width: $desktop-size) {
            font-size: 1.2rem;
          }
        }
      }

      &-right-content {
        background-color: $color-banner;
        padding: 6rem 0;

        @media only screen and(min-width: $tablet-size) {
          padding: 6rem 0;
        }

        @media only screen and(min-width: $desktop-size) {
          padding: 2rem;
        }
      }
    }
  }

  section {

    &.mission {

      h2 {
        font-weight: normal;
        line-height: 1.8em;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }
    }

    &.news {

      .home-news-items, .all-news-button {
        margin-top: 2rem;
      }

      .additional-news-items {
        .news-item:last-of-type {
          border-bottom: none;
        }
      }
    }

    &.projects {

      h3 {
        margin-top: 0;
      }

      .breadcrumb {
        margin-bottom: 2rem;
      }

      .square-link {
        bottom: 1rem;
        font-size: 0.95rem;
        position: absolute;
      }
    }

    &:not(.hero) {
      padding-top: 4rem;

      @media only screen and(min-width: $desktop-size) {
        padding-top: 6rem;
      }
    }
  }
}

.application-link {
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}
