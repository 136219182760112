@import "_core/all";

@import "_includes/all";
@import "_layouts/all";
@import "_pages/all";
@import "_syntax_highlighting/manni";

.clearfix {
  &::after {
    clear: both;
    content: "";
    display: block;
  }
}
