.news-item {
    border-bottom: 1px solid $color-gray-cool-5;
    margin-bottom: 2rem;
    padding-bottom: 1rem;

    .news-list-date {
      font-family: $font-serif;
    }

    @media only screen and(min-width: $desktop-size) {
      margin-bottom: 0;
    }

    &:not(:first-child) {
      padding-top: 1rem;
    }

    &.featured-news-item {
      @media only screen and(min-width: $desktop-size) {
        border-bottom: 0;
        padding-bottom: 0;
      }
    }

    &-sm {
      h3 {
        line-height: 1.7rem;
        margin-top: 0.5rem;
      }
    }

    img {
      height: auto;
      width: 100%;
    }

    .square-link {
      font-size: 0.95rem;
    }
}

// styles for embedded videos
.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
}

.embed-container iframe, .embed-container object, .embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
