.census-logo-footer {
  display: inline-block;
  margin-bottom: 1rem;
  max-width: 8rem;
}

.footer-bottom {
  color: lighten($color-banner, 57);
  font-size: 0.9rem;
  margin-top: 2rem;
  text-align: center;

  a {
    color: $color-white;
    font-weight: bold;

    &:hover {
      color: $color-white;
    }
  }
}

.footer-seperator {
  display: inline-block;
  margin: 0 0.75rem;
}

.usa-footer_nav {

  a {
    color: $color-white;
    font-weight: bold;
    text-transform: uppercase;

    &:hover {
      color: $color-white;
    }

    @media only screen and(min-width: $desktop-size) {
      font-size: 0.9rem;
    }
  }

  li {
    margin-bottom: 0.5rem;
  }
}

.usa-footer__primary-section {
  background-color: $color-banner;
  padding: 3rem 0 2rem;

  h4 {
    color: $color-white;
    font-size: 1rem;
    margin: 0 0 1rem;
  }
}