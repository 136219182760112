.team-member-card {
  border: 1px solid $color-border;
  border-radius: 3px;
  box-shadow: 3px 10px 13px 0px rgba(0,0,0,0.15);
  height: 100%;
  padding-bottom: 1rem;
  position: relative;

  .member-title {
    font-weight: normal;
  }

  img {
    display: block;
    margin: auto;
    height: auto;
    width: 100%;
  }

  .bio-content {
    margin: 1rem;
  }

  .skills-container {
    .skills-tag {
      font-size: 0.9rem;
      color: $color-white;
      .skill-text {
        padding: .5rem;
        border-radius: 5px;
      }
    }
    // color palette inspo is http://colorsafe.co/ purple palette
    .ai-governance {
      background-color: #9B59B6;
    }
    .auditing, .ai-evaluation {
      background-color: #7462E0;
    }
    .cloud-management {
      background-color: #7462E0;
    }
    .participatory-research {
      background-color: #7D314C;
    }
    .computer-vision {
      background-color: #886288;
    }
    .data-governance {
      background-color: #D2527F;
    }
    .data-science, .data-science-ai-engineering {
      background-color: #DB0A5E;
    }
    .data-engineering {
      background-color: #8859B6;
    }
    .human-computer-interaction {
      background-color: #7600A8;
    }
    .privacy-enhancing-technologies {
      background-color: #B200FD;
    }
    .product-strategy {
      background-color: #7023B7;
    }
    .fullstack-engineering {
      background-color: #7659B6;
    }
    .human-centered-design {
      background-color: #A74165;
    }
    .machine-learning {
      background-color: #B500B5;
    }
    .tech-policy {
      background-color: #5E50B5;
    }
    .technical-project-management, .technical-leadership {
      background-color: #5A4586;
    }
    .cybersecurity {
      background-color: #483D8B;
    }
  }
}

.member-card-content {
  padding: 0.5rem 1rem 0 1rem;
}

.team-member-heading {
  padding: 0rem 1rem;
  margin-bottom: 0;
}
