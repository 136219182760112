.more-news {
  padding-top: 4rem;

  .breadcrumb {
    margin-bottom: 2rem;
  }
}

.blog {

  main {

    &.main-content {
      padding-top: 2rem;
    }

    a {
      font-weight: bold;
      text-decoration: underline;
    }

    .title {
      font-weight: bold;
    }

    .paper-title {
      font-style: italic;
    }
  }

  .profile-img {
    max-width: 150px;
    width: 100%;
    float: left;
    margin-right: 2rem;
  }

  .hiring {
    margin-top: 6rem;

    @media only screen and(min-width: $tablet-size) {
      margin-top: 8rem;

    }
  }

  .news-content-container {

    @media only screen and(min-width: $tablet-size) {
      margin-top: 4rem;
    }

    @media only screen and(min-width: $tablet-size) {
      margin-top: 6rem;
    }
  }

  .news-content {

    p {
      font-size: 1.2rem;

      &.news-cta {
        font-size: 1rem;
      }
    }

    .square-link {
      margin-top: 1rem;
    }
  }

  .post-author, .news-date {
    font-family: $font-serif;
  }

  .news-fellow {
    border-bottom: 1px solid #000;
    margin-bottom: 2rem;
    padding-bottom: 1.5rem;

    p {
      font-size: 1.1rem;
    }
  }

  .news-image-container {
    margin-bottom: 2rem;
  }

  .news-item {
    @media only screen and(min-width: $tablet-size) {
      border-bottom: 0;
    }
  }

  .news-row {
    flex-direction: column-reverse;

    @media only screen and(min-width: $tablet-size) {
      flex-direction: row;
    }
  }
}
