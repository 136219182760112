.page-apply {
  
  section {
    margin-top: 2rem;

    &:first-child {
      margin-top: 0;
    }

    p {
      font-size: 1.1rem;
      line-height: 1.6em;
    }    

    &.intro {
      .position-list-item {

        p.position-subtitle {
          font-style: italic;
          margin-top: 0;
        }      
        
        h2 {
          margin-bottom: 0;
        }
      }      
    }

    &.faq {

      .grid-container {
        border-top: 1px solid black;
        padding-top: 2rem;
      }
    }
    
  }
}