.alignfull {
  margin-left: calc(50% - 50vw);
  width: 100vw;
}

.col-4 {
  flex-grow: 1;
}

.flex-container {
  display: flex;
}

