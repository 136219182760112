//Colors
$color-brand-blue: #112e51;
$color-brand-teal: #13E1BF;

$color-black: #000000;
$color-white: #FFFFFF;
$color-banner: #3E375A;
$color-border: #EEEEEE;

$color-gray-cool-5: #edeff0;

//Grid
$site-max-width: 1200px;
$desktop-size: 63em;
$tablet-size: 40em;
