.page-privacy-policy {
  
  main {
    
    h1 {
      margin-top: 0;
    }

    li, 
    p {
      font-size: 1.1rem;
      line-height: 1.6em;
    }
  }

}