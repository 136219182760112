.project-card {
  border: 1px solid $color-border;
  border-radius: 3px;
  box-shadow: 3px 10px 13px 0px rgba(0,0,0,0.15);
  height: 100%;
  padding-bottom: 2.5rem;
  position: relative;

  img {
    height: auto;
    width: 100%;
  }
}

.project-card-content {
  padding: 0.5rem 1rem 0 1rem;
}

.project-card-img {
  border-radius: 3px 3px 0 0 ;
}

.project-card-partner {
  color: #000;
  font-size: 0.8rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.project-cards {
  margin-bottom: 2rem;
}