.position {
  .page-content {
    p {
      font-size: 1.1rem;

      a {
        color: $color-black;
        text-decoration: underline;
      }
    }
  }

  h1 {
    margin: 0.5rem 0 0;
  }  
}