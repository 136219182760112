.faq {
  .usa-link {
    
    &:visited {
      color: $color-black;
    }
  }
}

.position-list-item {
  margin-bottom: 2rem;

  a {
    font-weight: bold;
  }
  
  p {
    font-size: 1.1rem;
    line-height: 1.6em;
  }

  .email-alt {
    font-size: 0.8rem;
  }
}