@import "../_includes/news-item";

body.news {

  main {

    &.main-content {
      padding-top: 2rem;
    }

    a {
      font-weight: bold;
      text-decoration: underline;
    }

    .title {
      font-weight: bold;
    }

    .paper-title {
      font-style: italic;
    }
  }

  section {

    &.story {

      .profile-img {
        max-width: 150px;
        width: 100%;
        float: left;
        margin-right: 2rem;
      }

      .news-content-container {

        @media only screen and(min-width: $tablet-size) {
          margin-top: 4rem;
        }

        @media only screen and(min-width: $tablet-size) {
          margin-top: 6rem;
        }
      }

      .news-content {

        p {
          font-size: 1.2rem;

          &.news-cta {
            font-size: 1rem;
          }
        }

        .square-link {
          margin-top: 1rem;
        }
      }

      .news-date {
        font-family: $font-serif;
      }

      .news-fellow {
        border-bottom: 1px solid #000;
        margin-bottom: 2rem;
        padding-bottom: 1.5rem;

        &:first-of-type {
          border-top: 1px solid #000;
          margin-top: 2rem;
          padding-top: 1.5rem;
        }

        p {
          font-size: 1.2rem;
        }
      }

      .news-image-container {
        margin-bottom: 2rem;
      }

      .news-row {
        flex-direction: column-reverse;

        @media only screen and(min-width: $tablet-size) {
          flex-direction: row;
        }
      }

    }

    &.more-news {
      padding-top: 4rem;

      .breadcrumb {
        margin-bottom: 2rem;
      }

      .news-item {
        @media only screen and(min-width: $tablet-size) {
          border-bottom: 0;
        }
      }
    }

    &.hiring {
      margin-top: 6rem;

      @media only screen and(min-width: $tablet-size) {
        margin-top: 8rem;

      }
    }

  }
}

.news-list {
  @media (min-width: 64em) {
    padding-left: 6rem;
    padding-right: 6rem;
  }
}